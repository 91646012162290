import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, from } from 'rxjs';
import { AuthService } from './auth.service';
import { take, map, tap } from 'rxjs/operators';
import { BillingInfo, SubscriptionStatus, } from '../models/models';


@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(private readonly auth: AuthService,
        private readonly db: AngularFireDatabase,
        private readonly router: Router) {

    }

    createAccount(id: string, email: string): Observable<number> {
        let now = Date.now()
        let payload = {}
        payload["email"] = email
        payload["id"] = id
        payload["info/email"] = email
        payload["info/id"] = id
        payload["info/first_login"] = now
        payload["subscription/email"] = email
        payload["crono/current"] = "slow"
        payload["crono/slow/is_enable"] = false
        payload["crono/fast/is_enable"] = false

        this.setSubscriptions(id, email)

        return from(this.db.object(`accounts/${id}`).update(payload)).pipe(
            map(_ => now)
        )
    }

    getAccount(id: string): Observable<number> {
        return this.db.object<number>(`accounts/${id}/info/first_login`).valueChanges().pipe(
            take(1)
        )
    }

    getBillingInfo(id: string) {
        return this.db.object<BillingInfo>(`accounts/${id}/billing`).valueChanges().pipe(
            take(1)
        )
    }

    getInfo(id: string) {
        return this.db.object(`accounts/${id}/info`).valueChanges().pipe(
            take(1)
        )
    }

    GEV_2 = "gev_2"
    NOGEV_2 = "nogev_2"
    GEV_TEST = "gev_test"

    getSubscription(id: string): Observable<SubscriptionStatus> {
        return this.db.object<SubscriptionStatus>(`accounts/${id}/subscription`).valueChanges().pipe(
            take(1),
            map(subscription => {
                subscription.is_monthly = subscription.type && (subscription.type == this.NOGEV_2 || subscription.type == this.GEV_2 || subscription.type == this.GEV_TEST)                
                return subscription
            })
        )
    }

    updateBillingInfo(id: string, billing: BillingInfo) {
        return this.db.object(`accounts/${id}/billing`).set(billing)
    }

    getIntro(id: string): Observable<boolean>{
        return this.db.object<boolean>(`accounts/${id}/intro`).valueChanges()
    }

    setIntro(id: string, value: boolean){
        return this.db.object(`accounts/${id}/intro`).set(value)
    }

    isAdmin(id: string): Observable<boolean>{
        return this.db.object<boolean>(`accounts/${id}/admin`).valueChanges().pipe(
            take(1),
            tap(status => {
                if(!status) this.router.navigate(['home'])
            })
        )}

    getAdmin(id: string): Observable<boolean>{
        return this.db.object<boolean>(`accounts/${id}/admin`).valueChanges()
    }

    setSubscriptions(id: string, email: string){
        let key = this.db.list(`subscriptions`).push('').key
        let subscription = {
            id,
            email
        }
        return this.db.object(`subscriptions/${key}`).set(subscription)
    }
}