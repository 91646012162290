import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscriptions } from '../models/models';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SupportService {

    constructor(
        private readonly db: AngularFireDatabase) {

    }

    public accountIdSupporting$: BehaviorSubject<string> = new BehaviorSubject(null)
    public isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(false)


    getSubscriptions(): Observable<Subscriptions[]> {
        return this.db.list<Subscriptions>(`subscriptions`).valueChanges()
    }

    public get supporting(): boolean{
        return this.accountIdSupporting$.value != null && this.isAdmin$.value
    }

    public get isAdmin(): boolean{
        return this.isAdmin$.value
    }

}